<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body">
        <Form
          class="formValidate"
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="110"
        >
          <div class="title_container">
            <div class="infoTitle_">设备保养单</div>
            <div class="action-area">
              <div class="actBtn cancle" @click="cancle">
                <!-- <img v-if="editState == false" src="/static/images/aoya/edit.png" alt=""> -->
                <i v-if="editState == false" class="icon1 iconbianji"></i>
                <i v-if="editState == true" class="icon1 iconiconquxiao-06"></i>
                <!-- <img v-if="editState == true" src="/static/images/aoya/cancel.png" alt=""> -->
                <span v-if="editState == false">编辑</span>
                <span v-if="editState == true">取消</span>
              </div>
              <div
                v-if="editState == true"
                class="actBtn save"
                @click="save('formValidate')"
              >
                <i class="icon1 iconbaocun"></i>
                <span>保存</span>
              </div>
            </div>
          </div>
          <div class="basicInfo">
            <div class="infoDetail">
              <FormItem>
                <Row>
                  <Col span="10">
                    <FormItem prop="byCode" label="设备保养单号">
                      <div class="textfont">{{ formValidate.byCode }}</div>
                      <!-- <Input v-if="editState == true" v-model="formValidate.byCode" placeholder="" style="width:70%;border:none;" disabled></Input>  -->
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="creatname" label="制单人">
                      <div class="textfont">{{ formValidate.creatname }}</div>
                      <!-- <Input  v-if="editState == true" v-model="formValidate.creatname" placeholder="" style="width:70%;border:none;" disabled></Input>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="10">
                    <FormItem prop="byDateplan" label="计划保养日期">
                      <div class="textfont">
                        {{
                          this.$globalFun.format_time(formValidate.byDateplan)
                        }}
                      </div>
                      <!-- <DatePicker v-if="editState == true" type="date" placeholder="" v-model="formValidate.byDateplan" style="width:70%;border:none;" disabled></DatePicker>  -->
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byCreattimme" label="制单日期">
                      <div class="textfont">
                        {{
                          this.$globalFun.format_time(formValidate.byCreattimme)
                        }}
                      </div>
                      <!-- <DatePicker v-if="editState == true" type="date" placeholder="" v-model="formValidate.byCreattimme" style="width:70%;border:none;" disabled></DatePicker>  -->
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col span="10">
                    <!-- <FormItem prop="deviceNum" label="设备编号"> -->
                    <FormItem prop="deviceNum" label="机台号">
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.deviceNum}}</div>
                                <Input v-if="editState == true" type="text" v-model="formValidate.deviceNum" placeholder="请输入机台号" style="width:70%;border:none;"></Input> -->
                      <Row>
                        <Col :span="8">
                          <div class="textfont">
                            {{ formValidate.deviceNum }}
                          </div>
                        </Col>
                        <Col :span="8">
                          <div
                            style="font-size: 16px;font-family: PingFangSC;font-weight: 500;color: #333333;"
                          >
                            设备编号:{{ formValidate.serialNumber }}
                          </div>
                        </Col>
                      </Row>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byContent" label="保养内容">
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.byContent}}</div> -->
                      <div class="textfont">
                        <Row style="border:none;">
                          <Col span="15">
                            {{ formValidate.byContent }}
                          </Col>
                        </Row>
                      </div>
                      <!-- <Input v-if="editState == true" type="text" v-model="formValidate.byContent" placeholder="请输入保养内容" style="width:70%;border:none;"></Input> -->
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </div>
          </div>
        </Form>
        <div class="otherInfo">
          <div class="infoTitle">
            <div
              style="width:110px;border-bottom:1px solid #DFE3E8;height:56px;line-height:56px;"
            >
              实施保养人
            </div>
          </div>
          <div class="infoDetail">
            <Form class="otherForm" :label-width="110">
              <FormItem>
                <Row style="border-top:1px solid #DFE3E8;">
                  <Col span="10">
                    <FormItem prop="dealDept" label="部门">
                      <div v-if="editState == false" class="textfont">
                        {{ formValidate.dealDept }}
                      </div>
                      <!-- <Input v-if="editState == true" v-model="formValidate.dealDept" placeholder="请输入" style="width:200px;"></Input>  -->
                      <Select
                        v-if="editState == true"
                        v-model="formValidate.dealDeptId"
                        placeholder="请选择部门"
                        style="width:70%;border:none;"
                        @on-change="changeDept"
                      >
                        <Option
                          v-for="(item, index) in deptList"
                          :key="index"
                          :value="item.deptId"
                          >{{ item.deptName }}</Option
                        >
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="dealNum" label="保养人">
                      <div v-if="editState == false" class="textfont">
                        {{ formValidate.dealname }}
                      </div>
                      <!-- @click="deptState= !deptState;" -->
                      <!-- <div v-if="editState == true" class="div-ipt" > -->
                      <!-- <Input v-if="editState == true" v-model="formValidate.dealname" placeholder="请输入"  :disabled="hasDeptId" style="width:70%;border:none;"></Input>  -->
                      <Select
                        v-if="editState == true"
                        v-model="deptUserId"
                        placeholder="请选择保养人"
                        style="width:70%;border:none;"
                        @on-change="changeDeptUser"
                        :disabled="hasDeptId"
                      >
                        <Option
                          v-for="(item, index) in deptuserList"
                          :value="item.userId"
                          :label="item.username"
                          :key="index"
                        >
                          <span>{{ item.username }}</span>
                          <span style="float:right;">{{
                            item.userNumber
                          }}</span>
                        </Option>
                      </Select>
                      <!-- </div> -->
                      <!-- <transition name="fade">
                                        <div v-if="deptState == true && formValidate.dealDeptId !='' " class="div-select" @blur="deptState = !deptState" >
                                            <div class="div-tree">
                                                <Select   v-model="deptUserId" placeholder="请选择保养人" style="width:70%;border:none;margin-left:110px;" @on-change="changeDeptUser">
                                                <Option v-for="(item,index) in deptuserList" :value="item.userId" :label="item.username" :key="index" >
                                                        <span>{{item.username}}</span>
                                                        <span style="float:right;">{{item.userNumber}}</span>
                                                    </Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </transition> -->
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="10">
                    <FormItem prop="byDealerId" label="员工编号">
                      <div v-if="editState == false" class="textfont">
                        {{ formValidate.dealNum }}
                      </div>
                      <Input
                        v-if="editState == true"
                        v-model="formValidate.dealNum"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byDealtime" label="实际保养日期">
                      <div v-if="editState == false" class="textfont">
                        {{
                          this.$globalFun.format_time(formValidate.byDealtime)
                        }}
                      </div>
                      <DatePicker
                        v-if="editState == true"
                        type="date"
                        placeholder="请选择日期"
                        v-model="formValidate.byDealtime"
                        style="width:70%;border:none;"
                      ></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Form>
          </div>
          <div class="infoTitle">
            <div
              style="width:110px;border-bottom:1px solid #DFE3E8;height:56px;line-height:56px;"
            >
              工作流日志
            </div>
          </div>
          <Table :columns="workhead" no-data-text=" " :data="workdata"></Table>
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editState: false,
      hasDeptId: false,
      deptState: false,
      deptList: [],
      deptuserList: [],
      deptUserId: "",
      userId: "",
      userName: "",
      formValidate: {
        byCode: "", // "BY20190917002"
        byContent: "", // "测试用例勿删"
        byCreaterId: "", // 3
        byCreattimme: "", // "2019-09-17"
        byDateplan: "", // "2019-09-19"
        byDealerId: "", // 9
        byDealtime: "", // "2019-09-18"
        byId: "", // 2
        byType: "", // "1"
        creatname: "", // "设备员工1"
        dealDept: "", // "保养一部"
        dealDeptId: "", // 部门id
        dealNum: "", // "admin8"
        dealname: "", // "保养员工1"
        deviceId: "", // 2
        deviceNum: "", // "1112"
        serialNumber: "", // 设备编号
        devId: "",
        devType: "",
        workshop: "",
        date: "",
        items: [
          {
            value: "",
            index: 1,
            status: 1,
            text: ""
          }
        ]
      },
      ruleValidate: {
        // devId: [
        //     { required: false, type: 'string', message: '请输入设备编号', trigger: 'blur' }
        // ],
        // devType: [
        //     { required: false, type: 'string', message: '请输入设备类型', trigger: 'blur' }
        // ]
      },
      workhead: [
        {
          title: "操作人",
          key: "dealName"
        },
        {
          title: "操作动作",
          key: "bylogType",
          render: (h, params) => {
            const arr = [];
            if (params.row.bylogType === 1) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      padding: "0"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        //   this.$router.push({
                        //   path: "/deviceManage/addDevmaintenance",
                        //   query: { byType: params.row.byType }
                        // });
                      }
                    }
                  },
                  "新建 "
                )
              );
            } else {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small",
                      disabled: true
                    },
                    style: {
                      marginRight: "5px"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        //   this.$router.push({
                        //   path: "/deviceManage/devmaintenanceDetail",
                        //   query: { byType: params.row.byType }
                        // });
                      }
                    }
                  },
                  "更新"
                )
              );
            }

            return h("div", arr);
          }
        },
        {
          title: "操作时间",
          key: "bydate"
        },
        {
          title: "备注",
          key: "byremark"
        }
      ],
      workdata: [
        {
          user: "John Brown",
          action: "操作",
          mark: "New York No. 1 Lake Park",
          date: "2016-10-03"
        },
        {
          user: "John Brown",
          action: "操作",
          mark: "New York No. 1 Lake Park",
          date: "2016-10-03"
        },
        {
          user: "John Brown",
          action: "操作",
          mark: "New York No. 1 Lake Park",
          date: "2016-10-03"
        },
        {
          user: "John Brown",
          action: "操作",
          mark: "New York No. 1 Lake Park",
          date: "2016-10-03"
        }
      ]
    };
  },
  methods: {
    changeDeptUser(e) {
      this.userId = this.deptUserId;
      this.deptState = false;
      // 赋值以后置空
      this.deptUserId = "";
      if (this.deptuserList.length > 0) {
        for (var i = 0; i < this.deptuserList.length; i++) {
          if (this.deptuserList[i].userId === e) {
            this.userName = this.deptuserList[i].username;
            //   this.userId = this.deptuserList[i].userId;

            this.formValidate.dealname = this.deptuserList[i].username;
            this.formValidate.byDealerId = this.deptuserList[i].userId;
            this.formValidate.dealNum = this.deptuserList[i].userNumber;
          }
        }
      }
    },
    getDeptUser() {
      this.axios({
        url: "/iext/back/organization/UserManagerController/deptuserList",
        method: "get",
        params: {
          deptId: this.formValidate.dealDeptId,
          searchText: ""
        }
      })
        .then((res) => {
          this.deptuserList = res.data.body.userList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeDept() {
      if (this.formValidate.dealDeptId !== "") {
        this.hasDeptId = false;
      }
      this.getDeptUser();
      for (var i = 0; i < this.deptList.length; i++) {
        if (this.formValidate.dealDeptId === this.deptList[i].deptId) {
          this.formValidate.dealDept = this.deptList[i].deptName;
          return;
        }
      }

      //   this.changeDeptUser();
    },
    // 获取部门列表
    getDeptList() {
      this.axios({
        url:
          "/iext/back/organization/DepartmentManagerController/searchSearchDeptList",
        method: "get",
        params: {
          deptText: ""
        }
      })
        .then((res) => {
          this.deptList = res.data.body.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancle() {
      this.getInfo();
      this.editState = !this.editState;
    },
    formatDate(dta) {
      var d = new Date(dta);
      var youWant =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      return youWant;
    },
    getInfo() {
      const that = this;
      this.axios({
        url: "/iext/back/device/DeviceController/maintainInfo",
        method: "get",
        params: {
          byId: this.$route.query.byId
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.formValidate.byCode = res.data.body.maintainInfo.byCode; // 保养单号
              that.formValidate.deviceNum =
                res.data.body.maintainInfo.deviceNum; // 设备编码
              that.formValidate.byContent =
                res.data.body.maintainInfo.byContent; // 保养内容
              that.formValidate.byCreaterId =
                res.data.body.maintainInfo.byCreaterId1; // 制单人id
              that.formValidate.byDateplan =
                res.data.body.maintainInfo.byDateplan; // 计划保养日期
              that.formValidate.creatname =
                res.data.body.maintainInfo.creatname; // 制单人姓名
              that.formValidate.byCreattimme =
                res.data.body.maintainInfo.byCreattimme; // 制单日期
              that.formValidate.byId = res.data.body.maintainInfo.byId; // 保养单id
              that.formValidate.byType = res.data.body.maintainInfo.byType; // 保养单状态 1待保养 2已保养
              that.formValidate.deviceId =
                res.data.body.maintainInfo.deviceId; // 设备id
              that.formValidate.byDealerId =
                res.data.body.maintainInfo.byDealerId; //  保养人id
              that.formValidate.dealNum = res.data.body.maintainInfo.dealNum; // 保养人姓名
              that.formValidate.dealname =
                res.data.body.maintainInfo.dealname;
            that.formValidate.byDealtime =
              res.data.body.maintainInfo.byDealtime; // 实际保养时间
              that.formValidate.dealDept =
                res.data.body.maintainInfo.dealDept; // 保养人部门
            that.formValidate.dealDeptId =
              res.data.body.maintainInfo.dealDeptId;
            that.workdata = res.data.body.byLoglist;

            that.deptUserId = res.data.body.maintainInfo.byDealerId;
            if (res.data.body.maintainInfo.dealDeptId === "") {
              this.hasDeptId = true;
            }
            that.getDeptUser();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // baocun
    save(typeNum) {
      this.axios({
        url:
          "/iext/back/device/DeviceController/dealMaintain?dealUserId=" +
          this.formValidate.byDealerId +
          "&byCode=" +
          this.$route.query.byCode +
          "&dealTime=" +
          this.formatDate(this.formValidate.byDealtime) +
          "&deviceId=" +
          this.$route.query.deviceId,
        method: "get"
      })
        .then((res) => {
          if (res.data.msg === "执行成功") {
            this.$Message.success("修改成功！");
            this.formValidate.byDateplan = this.formatDate(
              this.formValidate.byDateplan
            );
            this.formValidate.byDealtime = this.formatDate(
              this.formValidate.byDealtime
            );
            this.formValidate.byCreattimme = this.formatDate(
              this.formValidate.byDealtime
            );
            this.editState = false;
          } else {
            this.$Message.error("保存失败！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAdd() {
      this.index++;
      this.formValidate.items.push({
        value: "",
        index: this.index,
        status: 1
      });
    },
    handleRemove(index) {
      this.formValidate.items[index].status = 0;
    }
  },
  created() {},
  mounted() {
    this.getInfo();
    this.getDeptList();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .title_container {
      display: flex;
      justify-content: space-between;
      height: 84px;
      .infoTitle_ {
        // font-size:16px;
        // font-family:PingFangSC;
        // font-weight:500;
        // color:rgba(51,51,51,1);
        width: 200px;
        height: 84px;
        font-size: 24px;
        height: 24px;
        line-height: 84px;
        text-align: center;
        box-shadow: 0px -1px 0px 0px rgba(223, 227, 232, 1);
      }
      .action-area {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .actBtn {
          margin: 30px 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: rgba(99, 115, 129, 1);
          cursor: pointer;
        }
        .actBtn :hover {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #2980b9;
          cursor: pointer;
        }
        .cancle {
        }
        .save {
        }
      }
    }
    .basicInfo {
      .infoTitle_ {
        // font-size:16px;
        // font-family:PingFangSC;
        // font-weight:500;
        // color:rgba(51,51,51,1);
        height: 56px;
        width: 100%;
        line-height: 56px;
        text-align: center;
        background: rgba(228, 235, 241, 1);
        box-shadow: 0px -1px 0px 0px rgba(223, 227, 232, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFangSC;
        font-weight: 500;
        color: rgba(49, 69, 88, 1);
      }
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
          // border-bottom:1px solid #DFE3E8;
        }
        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
    }
  }
  .otherInfo {
    .infoTitle {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      margin-top: 40px;
      // border-bottom:1px solid #DFE3E8;
    }
    .infoDetail {
      .otherForm {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
          // border-bottom:1px solid #DFE3E8;
        }
        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
      }
    }
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-label {
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 500;
  color: #333;
  // border-bottom: 1px solid #DFE3E8;
  text-align: center;
}
.main-body .otherInfo .infoDetail .ivu-form-item /deep/ .ivu-form-item-label {
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 500;
  color: #333;
  // border-bottom: 1px solid #DFE3E8;
  text-align: center;
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-content {
  input {
    border: none;
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-content
  .textfont {
  height: 56px;
  line-height: 56px;
}
</style>
